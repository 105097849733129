import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Typography from '@mui/material/Typography';
//import Sheet from '@mui/material/Sheet';
import FormControl from '@mui/material/FormControl';
//import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Select from '@mui/material/Select';
//import Option from '@mui/material/Option';
import MenuItem from '@mui/material/MenuItem';
//import FormHelperText from '@mui/material/FormHelperText';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { visuallyHidden } from '@mui/utils';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

import Documentos from './Documentos';
import ChipSatge from './Elements/ChipSatge';

import { JsonToExcel } from "react-json-to-excel";

let rows = [];

function labelDisplayedRows({ from, to, count }) {
    return `${from}–${to} de ${count !== -1 ? count : `más de ${to}`}`;
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


const headCells = [
    {
        id: 'Tipo_Producto',
        numeric: false,
        disablePadding: true,
        label: 'Tipo operación',
    },
    {
        id: 'Name',
        numeric: false,
        disablePadding: true,
        label: 'Operación',
    },
    {
        id: 'id_oferta',
        numeric: false,
        disablePadding: true,
        label: '',
    },    
    {
        id: 'Closing_Date',
        numeric: true,
        disablePadding: false,
        label: 'Fecha inversión',
    },
    {
        id: 'Porcentaje',
        numeric: true,
        disablePadding: false,
        label: 'Participación',
    },
    {
        id: 'Ticket_op',
        numeric: true,
        disablePadding: false,
        label: 'Importe invertido',
    },
    {
        id: 'Periodo',
        numeric: true,
        disablePadding: false,
        label: 'Periodo',
    },
    {
        id: 'TIR_Total',
        numeric: true,
        disablePadding: false,
        label: 'ROI',
    },
    {
        id: 'Retorno',
        numeric: true,
        disablePadding: false,
        label: 'Retorno',
    },
    {
        id: 'TIR_Mensual',
        numeric: true,
        disablePadding: false,
        label: 'TIR mensual',
    },
    {
        id: 'TAE',
        numeric: true,
        disablePadding: false,
        label: 'Retorno anual',
    },
    {
        id: 'Beneficio',
        numeric: true,
        disablePadding: false,
        label: 'Beneficio',
    },
    {
        id: 'Fecha_desestimiento',
        numeric: true,
        disablePadding: false,
        label: 'Amortizado',
    }
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <thead>
            <tr>
                {headCells.map((headCell) => {
                    const active = orderBy === headCell.id;
                    return (
                        <th
                            key={headCell.id}
                            aria-sort={
                                active ? { asc: 'ascending', desc: 'descending' }[order] : undefined
                            }
                        >
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <Link
                                underline="none"
                                color="neutral"
                                textcolor={active ? 'primary.plainColor' : undefined}
                                component="button"
                                onClick={createSortHandler(headCell.id)}
                                fontWeight="lg"
                                sx={{
                                    whiteSpace: 'pre',
                                    '& svg.colArrow': {
                                        transition: '0.2s',
                                        transform:
                                            active && order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                    },
                                    '&:hover': { '& svg': { opacity: 1 } },
                                }}
                            >
                                {headCell.info ?
                                <Tooltip placement="right-start" title={headCell.info}><InfoOutlined sx={{ fontSize: 14, mr: 0.5 }} /> 
                                </Tooltip> : null}
                                {headCell.label} <ArrowDownwardIcon className="colArrow" sx={{ opacity: active ? 1 : 0, fontSize: 14 }} />
                                {active ? (
                                    <><Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                    
                                    </>
                                ) : null}
                                
                            </Link>
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


function creaJsonExport() {
    let jsonExport = [];
    rows.forEach(row => {
        jsonExport.push({
            'Tipo operación': row.Tipo_Producto,
            'Operación': row.Name,
            'Fecha inversión': row.Closing_Date,
            'Participación': row.Porcentaje,
            'Importe invertido': row.Ticket_op,
            'Periodo': row.Periodo,
            'ROI': row.TIR_Total,
            'Retorno': row.Retorno,
            'TIR mensual': row.TIR_Mensual,
            'Retorno anual': row.TAE,
            'Beneficio': row.Beneficio,
            'Amortizado': row.Fecha_desestimiento
        });
    });
    return jsonExport;
}


export default function TableCerradas(props) {

    rows = props.rows;

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Closing_Date');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event, newValue) => {
        //console.log(newValue);
        setRowsPerPage(parseInt(newValue.props.value, 10));
        setPage(0);
    };

    const getLabelDisplayedRowsTo = () => {
        if (rows.length === -1) {
            return (page + 1) * rowsPerPage;
        }
        return rowsPerPage === -1
            ? rows.length
            : Math.min(rows.length, (page + 1) * rowsPerPage);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <>

            <JsonToExcel
                title="Descargar Excel"
                data={creaJsonExport()}
                fileName="Ondara - Inversiones cerradas"
                btnClassName="pclp-btn-excel"
                btnColor="#6941c6"
            />

            <Table className="pclp-table-investments"
                aria-labelledby="tableTitle"
                sx={{
                    'width': '100%',
                }}
            >
                <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                />
                <tbody>
                    {rows.slice().sort(getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            const isItemSelected = isSelected(row.id_oferta);
                            const labelId = `enhanced-table-row-${index}`;

                            return (
                                <tr
                                    onClick={(event) => handleClick(event, row.id_oferta)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id_oferta}
                                >
                                    <td id={labelId}>
                                        <ChipSatge value={row.Tipo_Producto} />
                                    </td>
                                    <td>{row.Name}</td>
                                    <td><Documentos id_oferta={row.id_oferta} id_oc={row.id_oc} {...props} /></td>
                                    <td>{row.Closing_Date}</td>
                                    <td>{row.Porcentaje.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</td>
                                    <td>{row.Ticket_op.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}€</td>
                                    <td>{ row.Periodo !== 0 ? row.Periodo+' meses' : '-'}</td>
                                    <td>{ row.TIR_Total !==0 && row.TIR_Total !==null ? row.TIR_Total.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })+'%' : '-'}</td>
                                    <td>{ row.Retorno !== 0 ? row.Retorno.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })+'€' : '-'}</td>
                                    <td>{ row.TIR_Mensual !==0 ? row.TIR_Mensual.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })+'%' : '-'}</td>
                                    <td><span className="pclp-highlight">{row.TAE.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}%</span></td>
                                    <td><span className="pclp-highlight">{ row.Beneficio !== 0 ? row.Beneficio.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })+'€' : '-'}</span></td>
                                    <td>{row.Fecha_desestimiento !== '' ? row.Fecha_desestimiento : '-'}</td>

                                </tr>
                            );
                        })}
                    {emptyRows > 0 && (
                        <tr
                            style={{
                                height: `calc(${emptyRows} * 40px)`,
                                '--TableRow-hoverBackground': 'transparent',
                            }}
                        >
                            <td colSpan={11} aria-hidden />
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={5}>
                        <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    justifyContent: 'flex-start',
                                }}
                            >
                                Mostrar
                                <FormControl variant="standard" >
                                    <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                    </Select>
                                </FormControl>
                                filas
                            </Box>
                            </td>
                            <td colSpan={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Typography textAlign="center" sx={{ minWidth: 80 }}>
                                    {labelDisplayedRows({
                                        from: rows.length === 0 ? 0 : page * rowsPerPage + 1,
                                        to: getLabelDisplayedRowsTo(),
                                        count: rows.length === -1 ? -1 : rows.length,
                                    })}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <IconButton
                                        size="sm"
                                        color="neutral"
                                        variant="outlined"
                                        disabled={page === 0}
                                        onClick={() => handleChangePage(page - 1)}
                                        sx={{ bgcolor: 'background.surface' }}
                                    >
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                    <IconButton
                                        size="sm"
                                        color="neutral"
                                        variant="outlined"
                                        disabled={
                                            rows.length !== -1
                                                ? page >= Math.ceil(rows.length / rowsPerPage) - 1
                                                : false
                                        }
                                        onClick={() => handleChangePage(page + 1)}
                                        sx={{ bgcolor: 'background.surface' }}
                                    >
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </td>
                    </tr>
                </tfoot>
            </Table>
        
        </>
    );
}
